import React from 'react';

import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import Aggregations from 'components/Aggregations/Aggregations';

import { GlobalContextModel } from 'api/models/general';
import { GlobalContext } from 'context/globalContext';

import {
    IVouchersAggregationsModel
} from 'pages/Reports/business';

const useStyles = makeStyles((theme: Theme) => ({
    wrap: {
        position: 'relative',
        minHeight: '68px',
        marginBottom: theme.spacing(3),
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: theme.palette.background.paper,

        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    },
}));

const VouchersAggregations = ({
    aggregationsData
}: {
    aggregationsData: IVouchersAggregationsModel;
}) => {
    const {
        issuedVouchersQuantity,
        issuedTotalValue,
        cashoutVouchersQuantity,
        cashoutTotalValue,
        unpaidVouchersQuantity,
        unpaidTotalValue,
    } = aggregationsData;

    const classes = useStyles({});
    const { translations }: GlobalContextModel = React.useContext(GlobalContext);

    const data = [
        { title: translations['emp-reports-vouchers-voucher-status-issued'], value: issuedTotalValue, count: issuedVouchersQuantity, highlight: false },
        { title: translations['emp-reports-vouchers-voucher-status-cashed'], value: cashoutTotalValue, count: cashoutVouchersQuantity, highlight: false },
        { title: translations['emp-payout-status-unpaid'], value: unpaidTotalValue, count: unpaidVouchersQuantity, highlight: true },
    ];

    return (
        <div className={classes.wrap}>
            {
                data.map((item, index) => (
                    <Aggregations
                        key={index}
                        title={item.title}
                        value={item.value}
                        count={item.count}
                        highlight={item.highlight}
                    />
                ))
            }
        </div>
    );
};

export default VouchersAggregations;
